import { useEffect, useState, useContext, useRef, lazy } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import jwt from "jwt-decode";
import { MainContext } from '../App';
import { titleCase } from "../components/util/StringHelper";
import MobileHeader from "./MobileHeader";
import MenuDropdown from "./_MenuDropdown";
import AskSpecialistModal from "./modals/askSpecialistModal";

import HamburgerMenu from "../assets/images/HamburgerMenu.png";
import AiiConLghtGreen from "../assets/images/AiiConLghtGreen.webp";
import AiiConLghtGreen500 from "../assets/images/AiiConLghtGreen-p-500.webp";
import AiiConLghtGreen800 from "../assets/images/AiiConLghtGreen-p-800.webp";
import specialist from "../assets/images/SpecialistHeroNewCropped.png";
import ProfileIcon from "../assets/images/ProfileIcon.webp";
//const ProfileIcon = lazy(() => import('../assets/images/ProfileIcon.webp'));

function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const images = importAll(require.context('../assets/images', false, /\.(webp|png|jpe?g|svg)$/));

const Header = ({ statics, viewType, isMobile }) => {

  const { context, viewTypeTrigger, triggerChat, mainModalTrigger } = useContext(MainContext);
  const navigate = useNavigate();
  const sellServicesRef = useRef(null);
  const serviceTypesRef = useRef(null);
  const businessTypesRef = useRef(null);
  const whyManyHandRef = useRef(null);
  const adminTypesRef = useRef(null);

  const [authenticated, setAuthenticated] = useState();
  const [isAdmin, setIsAdmin] = useState(false);

  const [type, setType] = useState();//localStorage.getItem("type"));
  const [profileImage, setProfileImage] = useState(ProfileIcon);

  const location = useLocation();
  const home = location.pathname === "/";
  const [categories, setCategories] = useState(JSON.parse(localStorage.getItem('categories')));
  const [industries, setIndustries] = useState(JSON.parse(localStorage.getItem('industries')));
  const [isHeaderDown, setIsHeaderDown] = useState(false);
  const [showHeader, setShowHeader] = useState(true);

  useEffect(() => {
    if (context) {
      if (localStorage.getItem('authToken')) {
        localStorage.getItem('authToken')
        setAuthenticated(true);
        let admin_check = jwt(localStorage.getItem('authToken')).is_admin === true;
        if (admin_check) {
          setIsAdmin(true);
        };
      }

      setProfileImage(context && context.profile && context.profile.images.length > 0 ?
        context.profile.images.filter((i) => i.type === "profile_image").reverse()[0].url : ProfileIcon);
    }

    if ((statics && statics?.categories && !categories) || (statics && categories !== statics?.categories)) {
      setCategories(statics?.categories);
    } else if (localStorage.categories !== undefined && !categories) {
      setCategories(localStorage.categories);
    }

    if ((statics && statics?.industires && !industries) || (statics && industries !== statics?.industries)) {
      setIndustries(statics?.industries);
    } else if (localStorage.industries !== undefined && !industries) {
      setIndustries(localStorage.industries);
    }

    if (context && type === null) {
      setType(context.type);
    }

  }, [statics, context, viewType, categories, industries, type]);

  const switchType = async () => {
    if (localStorage.getItem("viewType") === "selling") {
      viewTypeTrigger("shopping");
    } else {
      viewTypeTrigger("selling");
    }

    if (categories === null) {
      setCategories(await statics.categories);
    }
    if (industries === null) {
      setIndustries(await statics.industries);
    }

    return;
  };

  const onMenuHover = (e) => {
    try {
      e.target.closest("nav.nav-menu-2").classList.add("w--open");
      e.target.closest("nav.nav-menu-2").ariaExpanded = "true";
      e.target.closest("nav.nav-menu-2").querySelector("nav.drop-down-list").classList.add("w--open");
      e.target.closest("nav.nav-menu-2").querySelector("nav.drop-down-list").ariaExpanded = "true";
    } catch (error) {
      console.log(error);
    }
  };

  const onMenuLeave = (e) => {
    try {
      e.target.closest("nav.nav-menu-2").classList.remove("w--open");
      e.target.closest("nav.nav-menu-2").ariaExpanded = "false";
      e.target.closest("nav.nav-menu-2").querySelector("nav.drop-down-list").classList.remove("w--open");
      e.target.closest("nav.nav-menu-2").querySelector("nav.drop-down-list").ariaExpanded = "false";
    } catch (error) {
      console.log(error);
    }
  };

  const onSubMenuHover = (e) => {
    try {
      // console.log(e.target);
      e.target.classList.add("w--open");
      e.target.ariaExpanded = "true";
      e.target.closest("div.w-dropdown").querySelector("nav.w-dropdown-list").classList.add("w--open");
      e.target.closest("div.w-dropdown").querySelector("nav.w-dropdown-list").ariaExpanded = "true";
    } catch (error) {
      console.log(error);
    }
  };

  const onSubMenuLeave = (e) => {
    try {
      //console.log(e.target);
      e.target.classList.remove("w--open");
      e.target.ariaExpanded = "false";
      e.target.closest("div.w-dropdown").querySelector("nav.w-dropdown-list").classList.remove("w--open");
      e.target.closest("div.w-dropdown").querySelector("nav.w-dropdown-list").ariaExpanded = "false";
    } catch (error) {
      console.log(error);
    }
  };
  const onMenuDropdownHover = (e) => {
    try {
      //console.log(e.target);
      e.target.classList.add("w--open");
      e.target.ariaExpanded = "true";
      e.target.closest("div.menu-dropdown").classList.add("w--open");
      e.target.closest("div.menu-dropdown").ariaExpanded = "true";
      e.target.closest("div.menu-dropdown").querySelector("nav.w-dropdown-list").classList.add("w--open");
      e.target.closest("div.menu-dropdown").querySelector("nav.w-dropdown-list").ariaExpanded = "true";
    } catch (error) {
      console.log(error);
    }
  };

  const onMenuDropdownLeave = (e) => {
    try {
      //console.log(e.target);
      e.target.closest("div.menu-dropdown").querySelector("nav.w-dropdown-list").classList.remove("w--open");
      e.target.closest("div.menu-dropdown").querySelector("nav.w-dropdown-list").ariaExpanded = "false";
      e.target.closest("div.menu-dropdown").classList.remove("w--open");
      e.target.closest("div.menu-dropdown").ariaExpanded = "false";
      e.target.classList.remove("w--open");
      e.target.ariaExpanded = "false";
    } catch (error) {
      console.log(error);
    }
  };

  const hideOnNavigate = (e) => {
    if (e.target.dataset.type === "services") {
      serviceTypesRef.current.querySelectorAll(".w--open").forEach(i => i.classList.remove("w--open"));
    }
    if (e.target.dataset.type === "admin") {
      adminTypesRef.current.querySelectorAll(".w--open").forEach(i => i.classList.remove("w--open"));
    }
    if (e.target.dataset.type === "sellservices") {
      sellServicesRef.current.querySelectorAll(".w--open").forEach(i => i.classList.remove("w--open"));
    }
    if (e.target.dataset.type === "business") {
      businessTypesRef.current.querySelectorAll(".w--open").forEach(i => i.classList.remove("w--open"));
    }
    if (e.target.dataset.type === "whymanyhands") {
      whyManyHandRef.current.querySelectorAll(".w--open").forEach(i => i.classList.remove("w--open"));
    }
  };

  const showSpecialistModal = (e) => {
    e.preventDefault();
    mainModalTrigger(<AskSpecialistModal callback={mainModalTrigger} />, "ask-specialist-modal", (
      <div className="support-div-block bg-white d-flex ask-specialist-modal-header">
        <h2>Book a session with a ManyHands Specialist.</h2>
        <img src={specialist} loading="lazy" alt="" />
      </div>));
  };

  const navigateTo = (e) => {
    e.preventDefault();
    if (authenticated && localStorage.getItem("viewType") === "shopping") {
      navigate('/for-you')
    } else if (authenticated && localStorage.getItem("viewType") === "selling") {
      navigate('/dashboard')
    } else {
      navigate('/')
    }
  }

  return (
    <>

      {isMobile ? (
        <MobileHeader showHeader={showHeader} profileImage={profileImage} authenticated={authenticated} switchType={switchType} viewType={viewType} pIndustries={industries} pCategories={categories} mobileTriggerChat={triggerChat} specialistTrigger={showSpecialistModal} verified_pro={context && context.profile && context.profile.is_verified_pro} />
      ) : (

        <div id="header" className="navbar-wrapper" >
          <div className={`container ${authenticated === true ? "no-space center-content" : ""}`}>
            <div data-collapse="medium" data-animation="default" data-duration="800" data-easing="ease" data-easing2="ease" role="banner" className="navbar w-nav">
              <div className="navbar-row">
                <div className="div-block-3">
                  <Link to="/" onClick={(e)=>navigateTo(e)} className="navbar-1-brand w-nav-brand" aria-label="home">
                    <img src={images["ManyHands-logo.webp"]} width="165" sizes="(max-width: 479px) 100vw, (max-width: 991px) 165px, (max-width: 1919px) 14vw, 165px" srcSet={`${images["ManyHands-logo-p-500.webp"]} 500w, ${images["ManyHands-logo-p-800.webp"]} 800w, ${images["ManyHands-logo-p-1080.webp"]} 1080w, ${images["ManyHands-logo-p-1600.webp"]} 1600w, ${images["ManyHands-logo-p-2000.webp"]} 2000w, ${images["ManyHands-logo-p-2600.webp"]} 2600w, ${images["ManyHands-logo.webp"]} 2605w`} alt="" className="image-2" />
                  </Link>


                  <nav onMouseLeave={(e) => onMenuLeave(e)} onMouseEnter={(e) => onMenuHover(e)} ref={sellServicesRef} role="navigation" className={`${viewType === "shopping" && "hidden"} nav-menu-2 space-right w-nav-menu`}>
                    <div data-hover="true" data-delay="0" data-ix="move-dropdown-arrow-on-hover" className="arrow-move w-dropdown">
                      <div className="navigation-link dropdown-toggle-2 w-dropdown-toggle" id="w-dropdown-toggle-0" aria-controls="w-dropdown-list-0" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                        <div className="text-block-17">Sell Service Packs</div>
                        <div className="drop-arrow w-icon-dropdown-toggle" style={{ transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)", transformStyle: "preserve-3d" }} aria-hidden="true">
                        </div>
                      </div>
                      <nav className="drop-down-list w-dropdown-list" id="w-dropdown-list-0" aria-labelledby="w-dropdown-toggle-0">
                        <Link data-type="sellservices" onClick={hideOnNavigate} to="/create-service-pack" className="dropdown-link w-dropdown-link" tabindex="0">Create a new Service Pack</Link>
                        <Link data-type="sellservices" onClick={hideOnNavigate} to="/sell-service-pack" className="dropdown-link w-dropdown-link" tabindex="0">Sell an existing Service Pack</Link>
                      </nav>
                    </div>
                  </nav>


                  <nav onMouseLeave={(e) => onMenuLeave(e)} onMouseEnter={(e) => onMenuHover(e)} ref={serviceTypesRef} role="navigation" className={`${viewType === "selling" && "hidden"} nav-menu-2 space-right w-nav-menu`}>
                    <div data-hover="true" data-delay="0" data-ix="move-dropdown-arrow-on-hover" className="arrow-move w-dropdown">
                      <div className="navigation-link dropdown-toggle-2 w-dropdown-toggle" id="w-dropdown-toggle-1" aria-controls="w-dropdown-list-1" aria-haspopup="menu" aria-expanded="false" role="button" tabIndex="0">
                        <div className="text-block-17">Service Types</div>
                        <div className="drop-arrow w-icon-dropdown-toggle" style={{ "transform": "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)", "transformStyle": "preserve-3d" }} aria-hidden="true">
                        </div>
                      </div>

                      <nav className="drop-down-list w-dropdown-list" id="w-dropdown-list-1" aria-labelledby="w-dropdown-toggle-1">
                        <Link data-type="services" onClick={hideOnNavigate} to={"/discover/services/"} className="dropdown-link w-dropdown-link" tabIndex="0">Discover Service Packs</Link>

                        {categories && categories.map((cat, index) => (
                          <div onMouseLeave={(e) => onSubMenuLeave(e)} onMouseEnter={(e) => onSubMenuHover(e)} key={"dr-menu" + index} data-hover="true" data-delay="0" className="dropdown-5 w-dropdown">
                            <div className="dropdown-link second w-dropdown-toggle" id={"w-dropdown-toggle-10" + index}
                              aria-controls={"w-dropdown-list-10" + index} aria-haspopup="menu" aria-expanded="false" role="button" tabIndex="0">
                              <HashLink data-type="services" onClick={hideOnNavigate} to={"/services?category=" + cat.slug} className="text-block-16 dropdown-link">{titleCase(cat.name)}&nbsp;&nbsp;—&gt;</HashLink>
                              <div className="nav-text-small">{cat.note}</div>
                            </div>
                            <nav className="drop-down-list in-drop w-dropdown-list" id={"w-dropdown-list-10" + index}
                              aria-labelledby={"w-dropdown-toggle-10" + index}>
                              <div>
                                {cat && cat?.sub_categories?.map((subcat, subindex) => (
                                  <HashLink data-type="services" onClick={hideOnNavigate} key={"sub" + subindex} to={"/services?category=" + cat.slug + "&s_category=" + subcat.slug} className="dropdown-link w-dropdown-link">{titleCase(subcat.name)}</HashLink>
                                ))}
                              </div>
                            </nav>
                          </div>
                        ))}

                      </nav>
                    </div>
                  </nav>

                  <nav onMouseLeave={(e) => onMenuLeave(e)} onMouseEnter={(e) => onMenuHover(e)} ref={businessTypesRef} role="navigation" className={`${viewType === "selling" && "hidden"} nav-menu-2 space-right w-nav-menu`}>
                    <div data-hover="true" data-delay="0" data-ix="move-dropdown-arrow-on-hover" className="arrow-move w-dropdown" >

                      <div className="navigation-link dropdown-toggle-2 w-dropdown-toggle" id="w-dropdown-toggle-7" aria-controls="w-dropdown-list-7" aria-haspopup="menu" aria-expanded="false" role="button" tabIndex="0">
                        <div className="text-block-17">Business Types</div>
                        <div className="drop-arrow w-icon-dropdown-toggle" style={{ "transform": "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg); transformStyle: preserve-3d" }} aria-hidden="true">
                        </div>
                      </div>

                      <nav className="drop-down-list w-dropdown-list" id="w-dropdown-list-7" aria-labelledby="w-dropdown-toggle-7">
                        <Link data-type="business" onClick={hideOnNavigate} to={"/discover/services/"} className="dropdown-link w-dropdown-link" tabIndex="0">Discover Service Packs</Link>

                        {industries && industries.map((ind, index) => (
                          <div onMouseLeave={(e) => onSubMenuLeave(e)} onMouseEnter={(e) => onSubMenuHover(e)} key={"dr-menu" + index} data-hover="true" data-delay="0" className="dropdown-5 w-dropdown">
                            <div className="dropdown-link second w-dropdown-toggle" id={"w-dropdown-toggle-20" + index}
                              aria-controls={"w-dropdown-list-20" + index} aria-haspopup="menu" aria-expanded="false" role="button" tabIndex="0">
                              <HashLink data-type="business" onClick={hideOnNavigate} to={"/services?industry=" + ind.slug} className="text-block-16 dropdown-link">{titleCase(ind.name)}&nbsp;&nbsp;—&gt;</HashLink>
                              <div className="nav-text-small">{ind.note}</div>
                            </div>
                            <nav className="drop-down-list in-drop w-dropdown-list" id={"w-dropdown-list-20" + index}
                              aria-labelledby={"w-dropdown-toggle-20" + index}>
                              <div>
                                {ind && ind?.children?.map((subind, subindex) => (
                                  <HashLink data-type="business" onClick={hideOnNavigate} key={"sub" + subindex} to={"/services?industry=" + ind.slug + "&s_industry=" + subind.slug} className="dropdown-link w-dropdown-link">{titleCase(subind.name)}</HashLink>
                                ))}
                              </div>
                            </nav>
                          </div>
                        ))}

                      </nav>
                    </div>
                  </nav>

                  <nav onMouseLeave={(e) => onMenuLeave(e)} onMouseEnter={(e) => onMenuHover(e)} ref={whyManyHandRef} role="navigation" className="nav-menu-2 w-nav-menu">
                    <div data-hover="true" data-delay="0" data-ix="move-dropdown-arrow-on-hover" className="arrow-move w-dropdown" >
                      <div className="navigation-link dropdown-toggle-2 w-dropdown-toggle" id="w-dropdown-toggle-14" aria-controls="w-dropdown-list-14" aria-haspopup="menu" aria-expanded="false" role="button" tabIndex="0">
                        <div className="text-block-17">Why ManyHands?</div>
                        <div className="drop-arrow w-icon-dropdown-toggle" style={{ "transform": "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg); transformStyle: preserve-3d" }} aria-hidden="true">
                        </div>
                      </div>
                      <nav className="drop-down-list w-dropdown-list" id="w-dropdown-list-14" aria-labelledby="w-dropdown-toggle-14">

                        <div onMouseLeave={(e) => onSubMenuLeave(e)} onMouseEnter={(e) => onSubMenuHover(e)} data-hover="true" data-delay="0" className="dropdown-5 w-dropdown" >
                          <div className="dropdown-link second w-dropdown-toggle" id="w-dropdown-toggle-15" aria-controls="w-dropdown-list-15" aria-haspopup="menu" aria-expanded="false" role="button" tabIndex="0">
                            <div className="text-block-16">Sell Services&nbsp;&nbsp;—&gt;</div>
                            <div className="nav-text-small">Sell your productised services or join our Partner Network.</div>
                          </div>
                          <nav className="drop-down-list in-drop w-dropdown-list" id="w-dropdown-list-15" aria-labelledby="w-dropdown-toggle-15">
                            <div>
                              <Link data-type="whymanyhands" onClick={hideOnNavigate} to="/sell-services" className="dropdown-link w-dropdown-link">Productise your Business</Link>
                              <Link data-type="whymanyhands" onClick={hideOnNavigate} to="/verified-pro" className="dropdown-link w-dropdown-link">Become a Verified Pro</Link>
                              <Link data-type="whymanyhands" onClick={hideOnNavigate} to="https://blog.manyhands.co.za/post/the-growing-260k-manyhands-partner-network" target="_blank" className="dropdown-link w-dropdown-link">Join our Partner Network</Link>

                            </div>
                          </nav>
                        </div>

                        <div onMouseLeave={(e) => onSubMenuLeave(e)} onMouseEnter={(e) => onSubMenuHover(e)} data-hover="true" data-delay="0" className="dropdown-5 w-dropdown" >
                          <div className="dropdown-link second w-dropdown-toggle" id="w-dropdown-toggle-15" aria-controls="w-dropdown-list-15" aria-haspopup="menu" aria-expanded="false" role="button" tabIndex="0">
                            <div className="text-block-16">ManyHands Plans&nbsp;&nbsp;—&gt;</div>
                            <div className="nav-text-small">Our subscription plans offer premium services and protection.</div>
                          </div>
                          <nav className="drop-down-list in-drop w-dropdown-list" id="w-dropdown-list-15" aria-labelledby="w-dropdown-toggle-15">
                            <div>
                              <Link data-type="whymanyhands" onClick={hideOnNavigate} to="/manyhandsplus" className="dropdown-link w-dropdown-link">What is ManyHands+</Link>
                              <Link data-type="whymanyhands" onClick={hideOnNavigate} to="/pricing#client" className="dropdown-link w-dropdown-link">ManyHands+</Link>
                              <Link data-type="whymanyhands" onClick={hideOnNavigate} to="/pricing" className="dropdown-link w-dropdown-link">Verified Pro</Link>
                            </div>
                          </nav>
                        </div>

                        <div onMouseLeave={(e) => onSubMenuLeave(e)} onMouseEnter={(e) => onSubMenuHover(e)} data-hover="true" data-delay="0" className="dropdown-5 w-dropdown">
                          <div className="dropdown-link second w-dropdown-toggle" id="w-dropdown-toggle-16" aria-controls="w-dropdown-list-16" aria-haspopup="menu" aria-expanded="false" role="button" tabIndex="0">
                            <div className="text-block-16">About Us&nbsp;&nbsp;—&gt;</div>
                            <div className="nav-text-small">Read more on why we exist and what we are all about. </div>
                          </div>
                          <nav className="drop-down-list in-drop w-dropdown-list" id="w-dropdown-list-16" aria-labelledby="w-dropdown-toggle-16">
                            <div>
                              <Link data-type="whymanyhands" onClick={hideOnNavigate} to="/about-us" className="dropdown-link w-dropdown-link">Why ManyHands?</Link>
                              <Link data-type="whymanyhands" onClick={hideOnNavigate} to="/support" className="dropdown-link w-dropdown-link">Contact Us</Link>
                            </div>
                          </nav>
                        </div>

                        <div onMouseLeave={(e) => onSubMenuLeave(e)} onMouseEnter={(e) => onSubMenuHover(e)} data-hover="true" data-delay="0" className="dropdown-5 w-dropdown">
                          <div className="dropdown-link second w-dropdown-toggle" id="w-dropdown-toggle-17" aria-controls="w-dropdown-list-17" aria-haspopup="menu" aria-expanded="false" role="button" tabIndex="0">
                            <div className="text-block-16">Updates&nbsp;&nbsp;—&gt;</div>
                            <div className="nav-text-small">Stay up to date with new features, business insights and partner updates.</div>
                          </div>
                          <nav className="drop-down-list in-drop w-dropdown-list" id="w-dropdown-list-17" aria-labelledby="w-dropdown-toggle-17">
                            <div>
                              <a data-type="whymanyhands" rel="noreferrer" onClick={hideOnNavigate} href="https://blog.manyhands.co.za/" target="_blank" className="dropdown-link w-dropdown-link">Blog</a>
                              <a data-type="whymanyhands" rel="noreferrer" onClick={hideOnNavigate} href="https://blog.manyhands.co.za/blog-categories/news" target="_blank" className="dropdown-link w-dropdown-link">News</a>
                              <Link data-type="whymanyhands" onClick={hideOnNavigate} to="/many-updates" className="dropdown-link w-dropdown-link">Updates</Link>
                              <Link data-type="whymanyhands" onClick={hideOnNavigate} to="/roadmap" className="dropdown-link w-dropdown-link">Roadmap</Link>
                            </div>
                          </nav>
                        </div>

                      </nav>
                    </div>
                  </nav>

                  {authenticated && isAdmin && (
                    <nav onMouseLeave={(e) => onMenuLeave(e)} onMouseEnter={(e) => onMenuHover(e)} ref={adminTypesRef} role="navigation" className={`nav-menu-2 space-right w-nav-menu`}>
                      <div data-hover="true" data-delay="0" data-ix="move-dropdown-arrow-on-hover" className="arrow-move w-dropdown">
                        <div className="navigation-link dropdown-toggle-2 w-dropdown-toggle" id="w-dropdown-toggle-18" aria-controls="w-dropdown-list-18" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                          <div className="text-block-17">Admin</div>
                          <div className="drop-arrow w-icon-dropdown-toggle" style={{ transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)", transformStyle: "preserve-3d" }} aria-hidden="true">
                          </div>
                        </div>
                        <nav className="drop-down-list w-dropdown-list" id="w-dropdown-list-18" aria-labelledby="w-dropdown-toggle-18">
                          <Link data-type="admin" onClick={hideOnNavigate} to="/admin/manage-service-packs" className="dropdown-link w-dropdown-link" tabindex="0">Manage Service Pack</Link>
                          <Link data-type="admin" onClick={hideOnNavigate} to="/admin/manage-service-pros" className="dropdown-link w-dropdown-link" tabindex="0">Manage Service Pros</Link>
                          <Link data-type="admin" onClick={hideOnNavigate} to="/admin/users" className="dropdown-link w-dropdown-link" tabindex="0">Users</Link>
                          <Link data-type="admin" onClick={hideOnNavigate} to="/admin/manage-purchases" className="dropdown-link w-dropdown-link" tabindex="0">Purchases</Link>
                          <Link data-type="admin" onClick={hideOnNavigate} to="/admin/works-with" className="dropdown-link w-dropdown-link" tabindex="0">Manage Works With</Link>
                          <Link data-type="admin" onClick={hideOnNavigate} to="/admin/certifications" className="dropdown-link w-dropdown-link" tabindex="0">Manage Certifications & Memberships</Link>
                        </nav>
                      </div>
                    </nav>
                  )}

                </div>
                <div className="navbar-controls">
                  <a href="/sign-up#client" className="button-small sign-up w-button" >Get Started</a>
                  <div className="navbar-buttons">

                    {authenticated && localStorage.type === "client" ? (
                      <Link onClick={hideOnNavigate} to="/sign-up#client" className="button w-button" style={{ height: "40px" }}>Get Started</Link>
                    ) : (
                      <Link onClick={hideOnNavigate} to={authenticated ? "/pricing" : "/sign-up#client"} className="button w-button" style={{ height: "40px" }}>{authenticated ? "Member Plans" : "Get Started"}</Link>
                    )}
                  </div>

                  <a href="#" onClick={(e) => { e.preventDefault(); triggerChat(); }} className="ai-button-link-block w-inline-block">
                    <div className="ai-button-div"><img src={AiiConLghtGreen} loading="lazy" width="36" sizes="(max-width: 479px) 100vw, (max-width: 991px) 36px, (max-width: 1919px) 3vw, 36px" alt="" srcSet={`${AiiConLghtGreen500} 500w, ${AiiConLghtGreen800} 800w, ${AiiConLghtGreen} 876w`} /></div>
                  </a>

                  <div onMouseLeave={(e) => onMenuDropdownLeave(e)} onMouseEnter={(e) => onMenuDropdownHover(e)} data-hover="true" data-delay="0" data-ix="move-dropdown-arrow-on-hover" className="menu-dropdown w-dropdown">
                    <div className="navigation-link menu-nav w-dropdown-toggle" id="w-dropdown-toggle-19" aria-controls="w-dropdown-list-19" aria-haspopup="menu" aria-expanded="false" role="button" tabIndex="0">
                      <img src={HamburgerMenu} loading="lazy" alt="" className="hamburger-menu" width="20" />
                      <img src={profileImage} loading="lazy" alt="" width="25" height="25" className="border-radius-50" style={{ objectFit: "cover" }} />
                    </div>

                    <MenuDropdown authorised={authenticated} profileImage={profileImage}
                      userType={context && context?.type}
                      slug={context && context?.profile && context?.profile.slug} email={context?.email}
                      switchType={switchType} viewType={viewType} 
                      profile={context && context.profile} />

                  </div>
                </div>
              </div>
              <div className="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0">
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
